<template>
  <div class="home">
    <cnt token="123" type="m"></cnt>
  </div>
</template>

<script>
import cnt from '../components/cnt.vue'

export default {
  name: 'HomeView',
  components: {
    cnt
  },
  data() {
   
  },
  created() {
    

  },
  beforeDestroy() {
     
  },
  methods: {
    s1(){
      this.ws.send('ccc');
    },
     


  }
}
</script>
 