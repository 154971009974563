import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' 
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { get } from '@/utils/request'
Vue.use(ElementUI);

Vue.config.productionTip = false

// import TRTC from 'trtc-js-sdk';
// import '@/utils/aegis.js';
 
// import { isMobile } from '@/utils/utils';
 


// Vue.prototype.$isMobile = isMobile;
 


Vue.prototype.get = get
Vue.prototype.rename = (imagePath) => {
  const currentTime = new Date().getTime();

   
  const newImagePath = imagePath.replace('.jpg', '__tm_'+currentTime + '.jpg');
  return newImagePath;
 
}
Vue.prototype.base_name = (imagePath) => {
 
  //  'aaa'.split('/')
  let a = imagePath.split('/')
  return a[a.length - 1];
 
}
Vue.prototype.$t = (imagePath) => {
  
    return imagePath;
 
}
function cache(k, v) {
    // console.log('dodo', v)
    if (typeof (v) == 'undefined') {
        // get
        // console.log('get')
        return JSON.parse(localStorage.getItem(k));
    } else if (v == null) {
        // del
        // console.log('del')
        localStorage.removeItem(k);
    } else {
        // set
        // console.log('set', v)
        localStorage.setItem(k, JSON.stringify(v));
    }

}

Vue.prototype.cache = cache
if (!cache('uid')) {
    const currentTime = new Date().getTime();
    cache('uid', currentTime)
}

Vue.prototype.jump = function (url, para = {}, replace = false) {

    router.replace({ path: url, query: para, replace: replace })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
