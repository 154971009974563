import { render, staticRenderFns } from "./comp-info-input.vue?vue&type=template&id=4b640b3e&scoped=true&"
import script from "./comp-info-input.vue?vue&type=script&lang=js&"
export * from "./comp-info-input.vue?vue&type=script&lang=js&"
import style0 from "./comp-info-input.vue?vue&type=style&index=0&id=4b640b3e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b640b3e",
  null
  
)

export default component.exports