<template>
    <div class="home">
        <comp-nav></comp-nav>
    <div class="content" :class="$isMobile && 'content-mobile'">
      <!-- quick demo 使用指引 -->
      <comp-guidance></comp-guidance>
      <!-- sdkAppId、secretKey、userId、roomId 参数输入区域 -->
      <p class="label">{{ $t('Params') }}</p>
      <div class="param-container" :class="$isMobile && 'param-container-mobile'">
        <comp-info-input
          label="sdkAppId" type="number" @change="handleValueChange($event, 'sdkAppId')"></comp-info-input>
        <comp-info-input
          label="secretKey" @change="handleValueChange($event, 'secretKey')"></comp-info-input>
        <comp-info-input
          label="userId" @change="handleValueChange($event, 'userId')"></comp-info-input>
        <comp-info-input
          label="roomId" type="number" @change="handleValueChange($event, 'roomId')"></comp-info-input>
      </div>
      <div class='alert'>
        <el-alert
          type="error"
          :closable="false"
        >
          <span>{{ $t("Alert")}} <a target="_blank" :href="$t('Url')">{{ $t("Click")}}</a></span>
        </el-alert>
      </div>
      <!-- 设备选择区域 -->
      <p class="label">{{ $t('Device Select') }}</p>
      <div class="param-container" :class="$isMobile && 'param-container-mobile'">
        <comp-device-select
          deviceType="camera" @change="handleValueChange($event, 'cameraId')"></comp-device-select>
        <comp-device-select
          deviceType="microphone" @change="handleValueChange($event, 'microphoneId')"></comp-device-select>
      </div>
      <!-- rtc 房间 -->
      <comp-room
        :sdkAppId="Number(sdkAppId)"
        :secretKey="secretKey"
        :userId="userId"
        :roomId="Number(roomId)"
        :cameraId="cameraId"
        :microphoneId="microphoneId"></comp-room>
    </div>
    
        <div class="list" v-if="type == 'm' ">
            <div class="itm" v-bind:class="{act: act  == i}"  v-for="(vo, i) in list"
            :key="i"
            @click="clc_img(i)"
            >
           
                <img :src="vo.thumb" alt="">    
            </div>
        </div>
        <div v-else>
            <div v-if="act == -1" class="waitt"> 等待操作</div>
            <div v-else class="" class="big_img">
                <img :src="list[act][show_k]" alt="">
            </div>
        </div>
        <el-dialog title="大图" :visible.sync="dialogVisible" width="80%" @close="close" >
            <div v-if="dialogVisible">
                

                 <img :src="list[act][show_k]" alt="" class="tcimg">    
                    <el-switch v-model="show_k" @change="change_showk" active-text="修" inactive-text="原" active-value="truing_s"
            inactive-value="org_s">
          </el-switch>
            </div>
        </el-dialog>

    </div>
</template>

<script>

import compNav from '@/components/comp-nav.vue';
import compGuidance from '@/components/comp-guidance.vue';
import compInfoInput from '@/components/comp-info-input.vue';
import compDeviceSelect from '@/components/comp-device-select.vue';
import compRoom from '@/components/comp-room.vue';
import { clearUrlParam } from '@/utils/utils';

export default {
    name: 'HomeView',
    components: {
    compNav,
    compGuidance,
    compInfoInput,
    compDeviceSelect,
    compRoom,
  },
    props : {
        token : '',
        type : '',
    },
    data() {
        return {
            dialogVisible : false,
            act : -1,
            ws: null,
            ws_live: true,
            list : [],
            show_k : 'truing_s',
            sdkAppId: 0,
            secretKey: '',
            userId: '',
            roomId: 0,
            cameraId: '',
            microphoneId: '',
            
        }
    },
    mounted() {
        this.create_ws()
        this.f5()

    },
    beforeDestroy() {
        this.ws_live = false
        this.ws.close()
    },
    methods: {
        handleValueChange(value, key) {
            this[key] = value;
        },
        f5() {
            this.get("/demo_photo").then((e) => {
                this.list = e.data.list
            })
        },
        send_wd(f, ...p){
            if (this.type == 'm') {
                 console.log(f, p)
                 this.ws.send(JSON.stringify({f:f, p:p}));
            }
           
        },
        rev_ws(f, ...p){
            if (this.type == 'c') {
                this[f](...p)
            }
            
        },
        close(){
            this.dialogVisible = false
            this.send_wd('close')
        },
        change_showk(e){
            console.log(e)
            this.send_wd('ws_change_type', e)
        },
        ws_change_type(e) {
            this.show_k = e 
            this.$forceUpdate()
        },
        clc_img(i) {
            this.act = i
            if (this.type == 'm') {
                this.dialogVisible = true
            }
            
            this.send_wd('clc_img', i)
        }, 
        s1() {
            this.ws.send('ccc');
        },
        create_ws() {
            let ths = this

            this.ws = new WebSocket(`ws://101.43.143.91:8080?token=${this.token}&t=${this.type}`);

            this.ws.onopen = function () {
                console.log('WebSocket connection opened');
            };

            this.ws.onmessage = function (event) {
                console.log(event.data)
                let d = JSON.parse(event.data)
                ths.rev_ws(d.f, ...d.p)
            };

            this.ws.onclose = function () {

                setTimeout(() => {
                    if (ths.ws_live) {
                        ths.create_ws()
                    }
                }, 1000);

                console.log('WebSocket connection closed');
            };

        },


    }
}
</script>

<style   scoped>

.list {
    display : flex;
    flex-wrap: wrap;
}

.itm {
    flex: 0 0 24%;

}
.itm img {
    width: 90%;
}
.act {
    border: 2px solid red;
}

.big_img  img {
    margin: auto;
    display: block;
    width: 70%;
    height: auto;
}
.tcimg {
       margin: auto;
    display: block;
    width: 70%;
    height: auto;
}
</style>>
 
 