import axios from 'axios'
import {
  MessageBox,
  Message,
  Loading
} from 'element-ui'

import router from '../router'

let gb_loading
let loading_conf = {
  background: 'rgba(0, 0, 0, 0.01)'
}
// create an axios instance
const service = axios.create({
  baseURL: 'https://lovedaka.com/api', // url = base url + request url
  timeout: 20000 // request timeout
})

 
service.interceptors.request.use(
  config => {
    // jwt 
    config.headers['token'] = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
    return config
  },
  error => {

    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    gb_loading.close()
    /** 验证 token */
    if (res.status == 2) {
      Message({
        message: '请先登录',
        type: 'error',
        duration: 3 * 1000
      })

      let curr_url = window.location.href;

      localStorage.setItem('pcwxbkurl', curr_url)

      router.push({ path: 'login', replace: true })
      return Promise.reject(new Error(res.msg || 'Error'))
    }
    /** 业务逻辑未通过 */
    if (res.status == 0) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(new Error(res.msg || 'Error'))
    }

    /***
     * code 等于其他情况..
     */
    return res.data

  },
  error => {
    gb_loading.close()
    Message({
      message: error,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

// gb_loading = Loading.service(loading_conf);
export function get(url, _data = {}, method = 'get', show_load = true) {
  if (show_load) {
    gb_loading = Loading.service(loading_conf);
  }


  let params, data = {}
  if (method == 'get') {
    params = _data
  } else {
    data = _data
  }
  return service({
    url: url,
    method: method,
    params: params,
    data: data,
  })
}

// export function down(url, _data = {}, method = 'post') {
//   gb_loading = Loading.service(loading_conf);
//   _data.sss = 'pc'
//   let params, data = {}
//   if (method == 'get') {
//     params = _data
//   } else {
//     data = _data
//   }
//   return service({
//     url: url,
//     method: method,
//     params: params,
//     data: data,
//     responseType: 'blob'
//   })
// }

export default service
