import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/m.vue' 

Vue.use(VueRouter)

const routes = [
    {
        path: '/pcvd',
        name: 'pcvd',

        component: () => import('@/views/pcvd/ctl_pc_list.vue')
    },
    {
        path: '/xd',
        name: 'dada',

        component: () => import('@/views/video_live/zhubu.vue')
    },
    {
        path: '/pcvd_ctl',
        name: 'pcvd_ctl',

        component: () => import('@/views/pcvd/ctl_pc_ctrl.vue')
    },
    {
        path: '/pcvd_show',
        name: 'pcvd_ctl',

        component: () => import('@/views/pcvd/show.vue')
    },
    {
        path: '/pcvd_sm',
        name: 'pcvd_sm',

        component: () => import('@/views/pcvd/sm.vue')
    },
    {
        path: '/',
        name: 'xd',

    component: () => import('@/views/huoshan_list.vue')
    },
    {
        path: '/rm',
      name: 'rm',

        component: () => import('@/views/huoshan.vue')
    },
    {
        path: '/login',
      name: 'login',

      component: () => import('@/views/login.vue')
    },
    {
        path: '/reg',
      name: 'reg',

      component: () => import('@/views/reg.vue')
    },
    {
        path: '/orgindex',
        name: 'xd',

        component: () => import('@/views/index.vue')
    },
  {
    path: '/f',
    name: 'home',
    component: HomeView
  },
  {
    path: '/c',
    name: 'about',
 
    component: () => import(  '../views/c.vue')
  },
  {
    path: '/rtc',
    name: 'about',

    component: () => import('../views/rtc.vue')
  },
  {
    path: '/room',
    name: 'room',

    component: () => import('../views/room.vue')
  },
  {
    path: '/txvd',
    name: 'dasdsa',

    component: () => import('../views/room_home.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
